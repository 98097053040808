import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '@core/services/auth.service';
import { AlertService } from '@shared/services/alert.service';

export const permissionsGuard: CanActivateFn = async (route, state) => {
  const router = inject(Router);
  const _authService = inject(AuthService);
  const modulePermissions = (route.data?.['permissions'] as string[] | undefined)?.map(item => item.toLowerCase().trim());
  const _alertService = inject(AlertService)

  if (!modulePermissions || modulePermissions == undefined) {
    return true
  }

  let hasPermission = await _authService.checkPermission(modulePermissions);

  if (hasPermission) {
    return true
  }


  _alertService.failedAlert('Access Denied')
  router.navigateByUrl('/access-denied');
  return false;
};
